/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

// core components
import white_logo from  'assets/img/logo_white.svg';


function CopyRight() {
  return (
    <>
      <footer className="footer footer-default footerBlack">
        <Container>
        <Row>
        <Col className="col-md-8 col-sm-12">
            <span className="footerBlackText">© 1988-2021 Eldridge Products, Inc. All Rights Reserved.  465 Reservation Rd., Marina, CA 93933</span>
            <nav>
              <ul>
                <li>
                  <a
                    href="/support/privacyinquiry/terms"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>
                </li>

              </ul>
            </nav>
          </Col>
          <Col className="col-md-4 col-sm-12">
          <div className="copyright" id="copyright">
          <img
            alt="EPI Footer Logo"
            className="img-raised footerCopyrightLogo"
            src={ white_logo }
          ></img><br />
            <span className="footerBlackCopyright">Proudly Designed & Manufactured in the USA</span>
            <br />
          </div>
          </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default CopyRight;
