/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  ListGroup,
  ListGroupItem
} from "reactstrap";
// core components

function SupportLinks() {
  return (
    <>
      <ListGroup flush>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/support/techsupport"
        >
          Technical Support
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/support/servicerequest"
        >
          Service Request
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="findrep"
        >
          Find a Representative
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/support/getquote"
        >
          Request a Quote
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/support/downloads"
        >
          Downloads
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/support/howtovideos"
        >
         How-To Videos
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/support/faq"
        >
         FAQ’s
        </ListGroupItem>
      </ListGroup>
    </>
  );
}

export default SupportLinks;
