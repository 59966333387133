/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  ListGroup,
  ListGroupItem,
} from "reactstrap";

// core components

function InformationLinks() {
  return (
    <>
      <ListGroup flush>
        <ListGroupItem
          className="footerMobileListGroupItem"
          href="/technicalinfo"
          tag="a"
        >
          Technical Information
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          href="/technicalinfo/fullguideflowmeters"
          tag="a"
        >
          Full Guide to Flow Meters
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          href="/technicalinfo/approvalscertificates"
          tag="a"
        >
          Approvals & Certificates
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          href="/technicalinfo/whitepapers"
          tag="a"
        >
          White Papers
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          href="/technicalinfo/gasesweflow"
          tag="a"
        >
          Gases We Flow
        </ListGroupItem>
        </ListGroup>
    </>
  );
}

export default InformationLinks;
