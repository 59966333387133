/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

// core components

function Logos() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <Row className="footerLogosContainer">
          <Nav className="footerLogosNav">
            <NavItem className="footerLogosNavLink">
              <NavLink href="https://ec.europa.eu/growth/single-market/ce-marking_en" target="_blank">
                <img
                  alt="CE Mark"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_3.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://www.csagroup.org/testing-certification/certification/" target="_blank">
                <img
                  alt="CSA Group"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_4.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://www.csagroup.org/testing-certification/marks-labels/csa-marks-labels-north-america/" target="_blank">
                <img
                  alt="CSA/CUS Mark"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_5.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://www.hse.gov.uk/fireandexplosion/atex.htm#whatatex" target="_blank">
                <img
                  alt="ATEX Certification"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_9.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://iec.ch/homepage" target="_blank">
                <img
                  alt="IEC Certificate"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_6.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://www.iecex.com/" target="_blank">
                <img
                  alt="IECEx Certificate"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_7.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://www.korea-certification.com/en/kcs-ex-certification-for-explosion-proof-components/" target="_blank">
                <img
                  alt="KOSHA Certification"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_8.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://www.fieldcommgroup.org/technologies/hart" target="_blank">
                <img
                  alt="HART Communication Protocol"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap_2.png").default}
                ></img>
              </NavLink>
            </NavItem>

            <NavItem className="footerLogosNavLink">
              <NavLink href="https://www.rtautomation.com/technologies/profibus/" target="_blank">
                <img
                  alt="Profibus Communication Protocol"
                  className="footerImgLogos"
                  src={require("assets/img/footer_assets/certificates/Bitmap.png").default}
                ></img>
              </NavLink>
            </NavItem>
          </Nav>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Logos;
