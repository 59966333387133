/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

// core components

function Links() {
  return (
    <>
      <footer
      className="footer footer-default"
      style={{
        'background-color': '#000E29',
        'padding-top': '40px'
      }}
      >
        <Container>
          <Row>
            <Col>
              <span className="footerTitle">
                Products
              </span>

              <Nav className="flex-column footerNavEpi">
                <NavItem>
                  <NavLink
                    className="footerLink"
                    href="/allproducts">
                    All Meters
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" href="/support/getquote">
                  Custom Order
                  </NavLink>
                </NavItem>
                <br />
                <NavItem>
                  <NavLink className="footerLink" style={{ 'color': '#B7BDC8', 'font-size': '14px' }} onClick={e => e.preventDefault()}>
                  Meter Models
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/haz">
                    - EPI-HAZ
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/gen">
                    - EPI-GEN
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/val">
                    - EPI-VAL
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/pure">
                    - EPI-PURE
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/xt">
                    - EPI-XT
                  </NavLink>
                </NavItem>
                <br />
                <NavItem>
                  <NavLink className="footerLink" style={{ 'color': '#B7BDC8', 'font-size': '14px' }}href="#" onClick={e => e.preventDefault()}>
                  Meters by Application
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/haz">
                    - Hazardous Locations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/gen">
                    - General Locations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/allproducts/val">
                    - High Value
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/allproducts/pure">
                    - High Purity
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/allproducts/xt">
                    - High Temperature
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>

            <Col>
              <span className="footerTitle">
                Information & Resources
              </span>

              <Nav className="flex-column footerNavEpi">
                <NavItem>
                  <NavLink className="footerLink"  tag={Link} to="/technicalinfo">
                    Technical Information
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" href="/technicalinfo/fullguideflowmeters">
                  Full Guide to Flow Meters
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" href="/technicalinfo/approvalscertificates">
                  Approvals & Certificates
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" href="/technicalinfo/whitepapers">
                  White Papers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" href="/technicalinfo/gasesweflow">
                  Gases We Flow
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>

            <Col>
              <span className="footerTitle">
                Support
              </span>

              <Nav className="flex-column footerNavEpi">
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/support/techsupport">
                    Technical Support
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/support/servicerequest">
                  Service Request
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/findrep">
                  Find a Representative
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/support/getquote">
                  Request a Quote
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/support/downloads">
                  Downloads
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/support/howtovideos">
                  How-To Videos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/support/faq">
                    FAQ’s
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>

            <Col>
              <span className="footerTitle">
                Company
              </span>

              <Nav className="flex-column footerNavEpi">
                <NavItem>
                  <NavLink className="footerLink"  tag={Link} to="/sitemap">
                    Sitemap
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/">
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/aboutus">
                    About Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="footerLink" tag={Link} to="/contactus">
                    Contact Us
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink>
                  </NavLink>
                </NavItem>
                <span className="footerTitle">
                  Customer Portal
                </span>

                <Nav className="flex-column footerNavEpi">
                  <NavItem>
                    <NavLink className="footerLink" tag={Link} to="/portal">
                      Account
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="footerLink" tag={Link} to="/login">
                    Log In
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="footerLink" tag={Link} to="/signup">
                    Sign Up
                    </NavLink>
                  </NavItem>
                </Nav>
              </Nav>
            </Col>

          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Links;
