/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  ListGroup,
  ListGroupItem
} from "reactstrap";

// core components

function CompanyLinks() {
  return (
    <>
      <ListGroup flush>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/"
        >
          Home
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/about"
        >
          About
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/contactus"
        >
          Contact Us
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/technicalinfo/approvalscertificates"
        >
          Certifications
        </ListGroupItem>

        <ListGroupItem
          className="footerMobileListGroupItem"
        >
          <span className="footerTitle"> Customer Portal</span>
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/portal"
        >
          Account
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/login"
        >
          Log In
        </ListGroupItem>
        <ListGroupItem
          className="footerMobileListGroupItem"
          tag={Link}
          to="/signup"
        >
          Sign Up
        </ListGroupItem>
      </ListGroup>
    </>
  );
}

export default CompanyLinks;
