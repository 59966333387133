/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col } from "reactstrap";
import useSwr from 'swr';
import fetcher from 'utils/fetcher';

function Subscribe() {
  const [userData, setUserData] = useState([]);
  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState(false);
  // const { data } = useSwr("/api/me", fetcher);

  const subscribe = async () => {
    if(!validateEmail(email)){
      return
    }

    setStatus('sending');
    let subscription_data = {
      email: email,
      subscriptions: 1
    }

    const checkSubscription = await axios.post(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/subscription/check`, {email: email});
    if(checkSubscription.data.status === 409){
      try{
        const sub = await axios.post(`${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/subscription`, subscription_data);
        setStatus('success');
        console.log('Show me sub 1.0 =>', sub);
      } catch(e) {
        //
        setStatus('error');
      }
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    console.log('Show me email 1.0 =>', validateEmail(email));
  }, [email]);

  return (
    <>
      <footer className="footer footer-default" style={{ 'background-color': '#012E82', 'color': '#ebf2ff'}}>
        <Row>
          <Col></Col>
          <Col className="text-center my-auto col-6 mailchimp-form">
            <span style={{ 'color': 'white', 'font-size': '28px', 'font-weight': '500', 'padding-bottom': '5px' }}>Stay Up to Date</span><br />
            <span>Get the latest news and product updates from the EPI team periodically. Don’t worry, we hate spam too!</span>
            <br /><br /><br />
            <div className="mailChimpForm">
              {
                status === "sending" &&
                <div className="mailChimpMessage"
                  style={{ color: "#fff" }}
                  >
                  subscribing...
                </div>}
              {status === "error" && (
                <div className="mailChimpMessage" style={{ color: "#0ec10e" }}>
                error
                </div>
              )}
              {status === "success" && (
                <div className="mailChimpMessage" style={{ color: "#0ec10e" }}>
                  You've been successfully subscribed.
                </div>
              )}
              <input
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={subscribe}>
                Subscribe
              </button>
            </div>
            <br /><br />
            <span style={{"color": "#b7bdc8"}}>By submitting your email address, you are giving EPI express authorization to send you marketing emails occasionally.<br />You may unsubscribe at any time by clicking the respective link in the email.</span>

            {/* <InputGroup>
              <Input
                style={{
                  'background-color': 'white',
                  'height': '48px',
                  'width': '200px',
                  'border': '1px solid #E3E3E3',
                  'border-radius': '6px',
                  'border-top-right-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-right': '0px',
                  'margin-right': '0px',
                }}
              />
              <Button
              className="my-auto primary"
              color="info"
              size="lg"
              style={{
                'border-radius': '6px',
                'border-top-left-radius': '0px',
                'border-bottom-left-radius': '0px',
                'border-left': '0px',
                'margin-left': '0px',
              }}
              >
                Subscribe
              </Button>
            </InputGroup> */}
            {/* <script id="mcjs">!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/d47c323ca22c484f1e72fa957/b0a1f78d7bb2b4d080fa99cc7.js");</script> */}
          </Col>
          <Col></Col>
        </Row>
      </footer>
    </>
  );
}

export default Subscribe;
