/*eslint-disable*/
import React from "react";
import { useMediaQuery } from 'react-responsive';


// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

// core components
function ContactInfo() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <>
      <footer className="footer footer-default footerContactInfo">
        <Container>
          <Row>
            <Col>
              <span className="footerContactInfoTitle">Call Us</span><br />
              <span className="footerContactInfoText"><a href="tel:+18003213569">1-800-321-FLOW</a></span>
              <br />
              <br />

              <span className="footerContactInfoTitle">Email Us</span><br />
              <span className="footerContactInfoText"><a href="mailto:info@epiflow.com">info@epiflow.com</a></span><br />
              <span className="footerContactInfoText"><a href="mailto:sales@epiflow.com">sales@epiflow.com</a></span>
            </Col>

            <Col>
              <span className="footerContactInfoTitle">Business Hours</span><br /><br />
              <Row>
                <Col><span className="footerContactInfoTextGray">Monday - Friday</span></Col>
                <Col><span className="footerContactInfoTextGray">7:30am-5pm PST</span></Col>
              </Row>

              <Row>
                <Col><span className="footerContactInfoTextGray">Saturday & Sunday</span></Col>
                <Col><span className="footerContactInfoTextGray">Closed</span></Col>
              </Row>

              <br />
              <span className="footerContactInfoTextGray">Need to contact us after hours?</span><br />
              <Button
                color="info"
                style={{ 'color': 'white', 'font-weight': '700' }}
                size="lg"
                outline
                href="/contactus"
              >
                Send Us a Message
              </Button>
            </Col>
            {
              isMobile? (<> </>) : (
                <Col>
                  <span className="footerContactInfoTitle">Social Media</span><br />
                  <span className="footerContactInfoTextGray">Follow EPI for Updates & Announcements</span>
                  <br />
                  <br />
                  <Row className="footerSocialIconRow">
                    <Col className="footerSocialIcon">
                      <a href="https://www.facebook.com/Eldridge-Products-Inc-EPI-306806035485/" target="_blank">
                        <img
                          alt="Facebook Social Media Link"
                          className="img-raised footerContactInfoSocialImgs"
                          style={{'background-color': 'white', 'padding': '5px', 'border-radius': '5px'}}
                          src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffooter%2Ffacebook.svg?alt=media&token=7beb3130-84a8-4efc-8c08-1d02fbef9eaa'
                        ></img>
                      </a>
                    </Col>
                    <Col className="footerSocialIcon">
                      <a href="https://www.instagram.com/epiflow/" target="_blank">
                        <img
                          alt="Instagram Social Media Link"
                          className="img-raised footerContactInfoSocialImgs"
                          style={{'background-color': 'white', 'padding': '5px', 'border-radius': '5px'}}
                          src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffooter%2Finstagram.svg?alt=media&token=687a8943-a3b0-44a2-816e-e5826258f14d'
                        ></img>
                      </a>
                    </Col>
                    <Col className="footerSocialIcon">
                      <a href="https://www.linkedin.com/company/eldridge-products-inc-" target="_blank">
                        <img
                          alt="LinkedIn Social Media Link"
                          className="img-raised footerContactInfoSocialImgs"
                          style={{'background-color': 'white', 'padding': '5px', 'border-radius': '5px'}}
                          src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffooter%2Flinkedin.svg?alt=media&token=2476c49f-e740-412f-a2b1-1e0e203f084a'
                        ></img>
                      </a>
                    </Col>
                    <Col className="footerSocialIcon">
                      <a href="https://twitter.com/epiflow" target="_blank">
                        <img
                          alt="Twitter Social Media Link"
                          className="img-raised footerContactInfoSocialImgs"
                          style={{'background-color': 'white', 'padding': '5px', 'border-radius': '5px'}}
                          src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffooter%2Ftwitter.svg?alt=media&token=90f1f1dc-9c4e-4060-a9fb-cedf605a155f'
                        ></img>
                      </a>
                    </Col>
                    <Col className="footerSocialIcon">
                      <a href="https://www.youtube.com/user/EldridgeProducts" target="_blank">
                        <img
                          alt="YouTube Social Media Link"
                          className="img-raised footerContactInfoSocialImgs"
                          style={{'background-color': 'white', 'padding': '5px', 'border-radius': '5px'}}
                          src='https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ffooter%2Fyoutube.svg?alt=media&token=553c8da4-743b-4563-9926-f494e679f454'
                        ></img>
                      </a>
                    </Col>
                  </Row>
                </Col>
              )
            }
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default ContactInfo;
